.logo {
  background-size: contain;
  display: inline-block;
  height: 150px;
  opacity: 1;
  position: relative;
  transform: none;
  width: 100%;

  .loading & {
    opacity: 0;
    transform: translateY(30px);
    transition: all .1s ease-out;
  }

  &__main {
    animation: glitch-main 5s infinite;
    background: no-repeat center center url("../img/logo.svg");
    background-size: contain;
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 2;
  }

  &__glitch {
    background: no-repeat center center url("../img/logo.svg");
    background-size: contain;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__glitch--1 {
    animation: glitch-1 1s infinite;
  }

  &__glitch--2 {
    animation: glitch-2 2s infinite;
    top: 5px;
  }

  &__glitch--3 {
    animation: glitch-3 1s infinite;
    left: 10px;
  }

  &__glitch--4 {
    animation: glitch-flash-1 10s infinite;
    filter: $filter-blue;
  }

  &__glitch--5 {
    animation: glitch-1 10s infinite;
    left: -2px;
    top: -5px;
    //animation: glitch-flash-2 10s infinite;
    //filter: $filter-pink;
  }

  @keyframes glitch-flash-1 {
    0%, 1% {
      opacity: 1;
      transform: translate3d(-2px, -2px, 0);
    }
    1.1%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes glitch-flash-2 {
    0%, 1% {
      opacity: 1;
      transform: translate3d(2px, 2px, 0);
    }
    1.1%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes glitch-main {
    0%, 1% {
      transform: translate3d(2px, 0, 0);
    }
    1.1%, 100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes glitch-1 {
    0% {
      opacity: 1;
      transform: translate3d(10px, 0, 0);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    2% {
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    4% {
      clip-path: polygon(0 10%, 100% 10%,
              100% 20%, 0 20%);
    }
    6% {
      clip-path: polygon(0 1%, 100% 1%,
              100% 2%, 0 2%);
    }
    8% {
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    10% {
      clip-path: polygon(0 44%, 100% 44%,
              100% 44%, 0 44%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(-10px, 0, 0);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }

  @keyframes glitch-2 {
    0% {
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      opacity: 1;
    }
    15% {
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    22% {
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      opacity: 1;
    }
    31% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      opacity: 0;
    }
    //45% {
    //  clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    //}
    //51% {
    //  clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    //}
    //63% {
    //  clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    //}
    //76% {
    //  clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    //}
    //81% {
    //  clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    //}
    //94% {
    //  clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    //}
    100% {
      //clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      opacity: 0;
    }
  }

  @keyframes glitch-3 {
    0% {
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      opacity: 1;
    }
    5% {
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    10% {
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    25% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    27% {
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    30% {
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    33% {
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    37% {
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      opacity: 1;
    }
    40% {
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      opacity: 0;
    }
    //45% {
    //  clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    //}
    //50% {
    //  clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    //}
    //53% {
    //  clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    //}
    //57% {
    //  clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    //}
    //60% {
    //  clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    //}
    //65% {
    //  clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    //}
    //70% {
    //  clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    //}
    //73% {
    //  clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    //}
    //80% {
    //  clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    //}
    100% {
      //clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      opacity: 0;
    }
  }
}