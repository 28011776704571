*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%
}

html {
  background-color: #000000;
  color: white;
  font-family: $font-family-text;
  font-size: 18px;
  line-height: 1.44;
}

p {
  margin: 0 0 1rem;
}

