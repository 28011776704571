.background {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
  max-width: 1400px;
  margin: auto;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    //transform: translate(-50%);
  }
}